import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpParams, HttpRequest } from '@angular/common/http';
import { StorageService } from '../../storage/storage.service';
import { Utlis } from '../../../utils/utlis.class';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToasterService } from '../../app-services/toaster/toaster.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CoreRequestService {

  //#region @VARIABLES
  public baseUrl: string = environment.baseUrl;
  private data: Object = {};
  //#endregion


  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private toaster: ToasterService,
    private router: Router
  ) { }

  private handleSuccess(res: any) {
    return JSON.parse(JSON.stringify(res ?? null));
  }

  private handleError(error: HttpErrorResponse) {
    let message = '';
    if (!error.error) {
      if (error['body']) {
        return throwError(error['body']['error']);
      } else {
        return throwError(error.statusText);
      }
    } else if (error.error instanceof ErrorEvent) {
      message = 'An error occurred: ' + error.error.message;
      this.toaster.showToast('danger', message);
      return throwError('An error occurred: ' + error.error.message);
    } else {
      if (!navigator.onLine) {
        message = 'YOUR CONNECTION IS DOWN';
        this.toaster.showToast('danger', message);
        return throwError('Check The Internet Connection');
      } else {
        let errorResult;
        if (error.statusText === 'Unknown Error' && error.status === 0 && error.ok === false) {
          errorResult = 'Please, try again later';
        }

        if (error.status == 404) {
          errorResult = error.error.message;
        }

        if (error.status >= 400 && error.status < 500) {
          errorResult = error.error.message;
        }

        if (error.status >= 500) {
          errorResult = error.error.message;
        }
        return throwError(errorResult);
      }
    }
  }

  getTimeOutFn() {
    if (!this.router.url.includes('login')) {
      const message = 'YOUR SESSION HAS EXPIRED';
      setTimeout(() => {
        this.storage.logout();
        this.router.navigateByUrl('/auth/login');
        this.toaster.showToast('danger', message);
      }, 1000);
    }
  }

  private serializeDataWithToken(data?: any, accessToken?: string) {
    if (data && accessToken) {
      data.accessToken = accessToken;
      const utility = new Utlis();
      const serializeToken = utility.ParamSerializerJQLike(data);
      return serializeToken;
    } else if (data) {
      data.accessToken = this.storage.getToken();
      if (data.accessToken) {
        const utility = new Utlis();
        const serializeToken = utility.ParamSerializerJQLike(data);
        return serializeToken;
      } else {
        return data;
      }
    } else {
      const token = {
        accessToken: this.storage.getToken()
      };
      if (token.accessToken) {
        const utility = new Utlis();
        const serializeToken = utility.ParamSerializerJQLike(token);
        return serializeToken;
      } else {
        return {};
      }
    }
  }

  public post(
    formData: object,
    path?: string,
    dataSearch?: any,
    reqHeaders?: any,
    responseCustomType?: string
  ): Observable<any> {
    this.baseUrl;
    const params = (this.data = dataSearch);

    const URL = this.baseUrl + path;
    const body = formData;

    const httpParams = { fromObject: params };

    const options: any = {
      headers: reqHeaders,
      observe: 'body',
      params: new HttpParams(httpParams),
      reportProgress: false,
      responseType: responseCustomType ? responseCustomType : 'json',
      withCredentials: false,
    };

    return this.http.post(URL, body, options).pipe(
      tap((res) => this.handleSuccess(res)),
      catchError((error) => this.handleError(error))
    );
  }

  public upload(
    formData: object,
    path?: string,
    dataSearch?: any,
    reqHeaders?: any,
    responseCustomType?: string
  ) {
    this.baseUrl;
    const params = (this.data = dataSearch);

    const URL = this.baseUrl + path;
    const body = formData;

    const httpParams = { fromObject: params };
    const options: any = {
      headers: reqHeaders,
      observe: 'body',
      params: new HttpParams(httpParams),
      reportProgress: true,
      responseType: responseCustomType ? responseCustomType : 'json',
      withCredentials: false,
    };
    const req = new HttpRequest('POST', URL, body, {
      reportProgress: true,
      headers: reqHeaders,
      params: new HttpParams(httpParams),
    });

    return this.http
      .request(req)
      .pipe(catchError((error) => this.handleError(error)));
  }

  public get(
    path?: string,
    data?: any,
    reqHeaders?: any,
    responseCustomType?: string
  ): Observable<any> {
    this.baseUrl;
    const params = (this.data = data);
    const URL = this.baseUrl + path;
    const httpParams = { fromObject: params };
    const options: any = {
      headers: reqHeaders,
      observe: responseCustomType ? 'response' : 'body',
      params: new HttpParams(httpParams),
      responseType: responseCustomType ? responseCustomType : 'json',
      withCredentials: false,
    };

    return this.http.get(URL, options).pipe(
      tap((res) => this.handleSuccess(res)),
      catchError((error) => this.handleError(error)));
  }

  public put(
    formData: any,
    path?: string,
    dataSearch?: any,
    reqHeaders?: any,
    responseCustomType?: string
  ): Observable<any> {
    this.baseUrl;
    const params = (this.data = dataSearch);

    const URL = this.baseUrl + path;
    const body = formData;

    const httpParams = { fromObject: params };
    const options: any = {
      headers: reqHeaders,
      observe: 'body',
      params: new HttpParams(httpParams),
      reportProgress: false,
      responseType: responseCustomType ? responseCustomType : 'json',
      withCredentials: false,
    };

    return this.http.put(URL, body, options).pipe(
      tap((res) => this.handleSuccess(res)),
      catchError((error) => this.handleError(error)));
  }

  public patch(
    formData: any,
    path?: string,
    dataSearch?: any,
    reqHeaders?: any,
    responseCustomType?: string
  ): Observable<any> {
    this.baseUrl;
    const params = (this.data = dataSearch);

    const URL = this.baseUrl + path;
    const body = formData;

    const httpParams = { fromObject: params };
    const options: any = {
      headers: reqHeaders,
      observe: 'body',
      params: new HttpParams(httpParams),
      reportProgress: false,
      responseType: responseCustomType ? responseCustomType : 'json',
      withCredentials: false,
    };

    return this.http.patch(URL, body, options).pipe(
      tap((res) => this.handleSuccess(res)),
      catchError((error) => this.handleError(error)));
  }

  public delete(path?: string, dataSearch?: any) {
    this.baseUrl;
    const params = (this.data = dataSearch);

    const URL = this.baseUrl + path;

    const httpParams = { fromObject: params };
    const options: any = {
      observe: 'body',
      params: new HttpParams(httpParams),
      reportProgress: false,
      responseType: 'json',
      withCredentials: false,
    };

    return this.http.delete(URL, options).pipe(
      tap((res) => this.handleSuccess(res)),
      catchError((error) => this.handleError(error)));
  }

  public getFullUrl(path?: string, dataSearch?: any, removeApi?: boolean) {
    this.baseUrl;
    this.data = dataSearch;

    const search = this.serializeDataWithToken(this.data);

    if (removeApi) {
      this.baseUrl = this.baseUrl.replace('api/', '');
    }
    const params = (this.data = dataSearch);

    const URL = this.baseUrl + path;

    return {
      url: URL,
      search: search,
    };
  }
}
