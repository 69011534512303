import { Injectable } from '@angular/core';
import { CoreRequestService } from '../../api-services/core-request/core-request.service';
import { Transaction } from '../../../models/transaction/transaction.class';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  public updateTransactions$: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor(private _coreRequest: CoreRequestService) { }

  getTransactions(filter?: any) {
    return this._coreRequest.get(Transaction.BaseUrl, filter);
  }
  getPending() {
    return this._coreRequest.get(Transaction.Pending);
  }

  getTransactionsById(id: any) {
    return this._coreRequest.get(Transaction.BaseUrl + '/' + id);
  }

  getTransfers(id: any, filter?:any) {
    return this._coreRequest.get(Transaction.Transfer + '/' + id, filter);
  }

  confirmDeposit(id: number) {
    const data = {}
    if(id) {
      data['id'] = id;
    }
    return this._coreRequest.post(data, Transaction.ConfirmDepositUrl)
  }

  receivedDeposit(id: number) {
    const data = {}
    if(id) {
      data['id'] = id;
    }

    data['type'] = 'RECEIVED';

    return this._coreRequest.post(data, Transaction.ReceivedDepositUrl)
  }

  confirmAllReceivedDeposits() {
    return this._coreRequest.post({}, Transaction.ConfirmAllReceivedDepositsUrl)
  }

  cancelDeposit(formData: any) {
    return this._coreRequest.post(formData, Transaction.CancelDepositUrl)
  }

  confirmWithdraw(id: number) {
    const data = {}
    if(id) {
      data['id'] = id;
    }
    return this._coreRequest.post(data, Transaction.ConfirmWithdrawalUrl)
  }

  cancelWithdraw(formData: any) {
    return this._coreRequest.post(formData, Transaction.CancelWithdrawalUrl)
  }

  getInsights() {
    return this._coreRequest.get(Transaction.insightsUrl);
  }

  sharedUpdateTransactions(value: any) {
    this.updateTransactions$.next(value);
  }
}
