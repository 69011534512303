import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedChangesService {
  public pendingTransaction$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public currentPlanTap$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  sharedPendingTransaction(users: any) {
    this.pendingTransaction$.next(users);
  }

  sharedCurrentPlanTap(users: any) {
    this.currentPlanTap$.next(users);
  }
}
