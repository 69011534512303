import { Injectable } from '@angular/core';
import { NbComponentStatus, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastrService: NbToastrService) { }

  showToast(type: NbComponentStatus, body?: string, duration?: any, position?: any, preventDuplicates?: boolean) {
    const config = {
      status: type, // ['primary', 'success', 'info', 'warning', 'danger']
      duration: (duration) ? duration : 3000,
      position: (position) ? position : 'top-right', // ['top-right', 'top-left']
      preventDuplicates: (preventDuplicates) ? true : false,
    };

    this.toastrService.show(undefined, body, config);
  }
}
