import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../../@core/services/app-services/auth/auth.service';
import { Router } from '@angular/router';
import { StorageService } from '../../../@core/services/storage/storage.service';
import { NbWindowService, NbWindowControlButtonsConfig, NbWindowRef} from '@nebular/theme';
import { NotificationsComponent } from '../notifications/notifications.component';
import { SharedChangesService } from '../../../@core/services/app-changes/shared-chnages.service';
import { TransactionService } from '../../../@core/services/app-services/transaction/transaction.service';
import { ToasterService } from '../../../@core/services/app-services/toaster/toaster.service';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  countPending: any;
  dataPending: any[] = [];
  nbWindow:any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    }
  ];

  currentTheme = 'default';
  reloadPending:any;

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private windowService: NbWindowService,
    private layoutService: LayoutService,
    private transactionService: TransactionService,
    private auth: AuthService,
    private router: Router,
    private toaster: ToasterService,
    private storage: StorageService,
    private _shared: SharedChangesService,
    private breakpointService: NbMediaBreakpointsService) {
      this.user = JSON.parse(this.storage.getClient());
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.detectTransactionsChanges();

    this.menuService.onItemClick()
    .pipe(
      filter(({ tag }) => tag === 'user-menu'),
      map(({ item: { title } }) => title),
    )
    .subscribe(title => {
      if (title == "Profile") {
      this.router.navigate(['/pages/profile'])
    } else if (title == "Log out") {
      this.storage.logout();
      this.router.navigate(['/auth/login']);
      this.toaster.showToast('warning', 'logged out', 3000);
      }
    });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.getTransactions();
  }

  reloadData() {
    this.reloadPending = setInterval(() => {
                    this.getTransactions();
                  }, 120000);
  }

  ngOnDestroy() {
    clearInterval(this.reloadPending);
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTransactions() {
    clearInterval(this.reloadPending);
    this.dataPending = []
    this.transactionService.getPending().subscribe(res => {
      if(res) {
        this.dataPending.push(...res.pending_validated_investor);
        this.dataPending.push(...res.pending_payments);
        this.countPending = this.dataPending.length;
        this._shared.sharedPendingTransaction(this.dataPending);
        this.reloadData();
      }
    });
  }

  detectTransactionsChanges() {
    this.transactionService.updateTransactions$.subscribe(res => {
      if(res) {
        this.getTransactions();
      }
    })
  }

  openWindow() {
    const buttonsConfig: NbWindowControlButtonsConfig = {
      minimize: false,
      maximize: false,
      fullScreen: true,
      close: true,
    };
    this.nbWindow = this.windowService.open(NotificationsComponent, { title: `Notifications`, buttons: buttonsConfig});
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
