export class Auth {
  private static baseUrl = '/api/admin';
  private static signIn = 'sign-in';
  private static refreshToken = '/token/refresh';
  private static currentAdminUrl = '/current';
  private static changePassword = 'change-password';

  static get BaseUrl() {
    return this.baseUrl;
  }

  static get SignIn() {
    return this.baseUrl + '/' + this.signIn;
  }

  static get ChangePassword() {
    return this.baseUrl + '/' + this.changePassword;
  }

  static get RefreshToken() {
    return this.baseUrl + this.refreshToken;
  }

  static get CurrentAdminUrl() {
    return this.baseUrl + this.currentAdminUrl;
  }
}
