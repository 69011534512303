import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import {  NavigationExtras, Router } from '@angular/router';
import { SharedChangesService } from '../../../@core/services/app-changes/shared-chnages.service';
import { NbWindowRef } from '@nebular/theme';


@Component({
  selector: 'ngx-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  data: any[] = [];

    constructor(
    private router: Router,
    private _shared: SharedChangesService,
    protected ref: NbWindowRef<NotificationsComponent>
  ) {
  }

  ngOnInit(): void {
    this.getTransactions();
  }

  getTransactions() {
    this._shared.pendingTransaction$.subscribe(res => {
      if(res) {
        this.data = res;
        for(let item of this.data) {
          if (item.hasOwnProperty('validate')) {
            item.created_at = item.updated_at;
          }
        }
        this.data.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      }
    })
  }

  routeTransactions(data?: any) {
    let navigationExtras: NavigationExtras;
    if(data.id == 'pending') {
      navigationExtras = {
        queryParams: {
          type: "pending"
        },
      }
    }else {
      navigationExtras = {
        queryParams: {
          id: data.id
        },
      }
    }
    data.hasOwnProperty('validate')? this.router.navigate([`/pages/users`], navigationExtras) : this.router.navigate([`/pages/transactions`], navigationExtras);
    this.ref.close();
  }

}
