export class Utlis {

  /**
   * ParamSerializerJQLike
   * @function
   * @description [Param Serializer for requests Like jquery]
   * @see [$httpParamSerializerJQLike]
   * @see [https://docs.angularjs.org/api/ng/service/$httpParamSerializerJQLike]
   * @param {object} data [Data to serialize]
   * @return returnData [serialized data]
   */
  public ParamSerializerJQLike(data: any) {
    let returnData = '';
    let count = 0;
    for (const i in data) {
      if (!{}.hasOwnProperty.call(data, i)) {
        continue;
      }
      if (count === 0) {
        returnData += i + '=' + data[i];
      } else {
        returnData += '&' + i + '=' + data[i];
      }
      count = count + 1;
    }
    return returnData;
  }
  
}