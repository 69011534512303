import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-toggle-visible-password',
  templateUrl: './toggle-visible-password.component.html',
  styleUrls: ['./toggle-visible-password.component.scss']
})
export class ToggleVisiblePasswordComponent {
  showPassword: boolean;
  @Output() toggleShow: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  toggle() {
    this.showPassword = !this.showPassword
    this.toggleShow.emit(this.showPassword);
  }

}
