<section>
  <div class="content">
    <div *ngFor="let item of data" (click)="routeTransactions(item)">
      <div class="notification" *ngIf="item.hasOwnProperty('investor')">
        <div class="avatar trans">
          <span>{{item.investor.name[0] | uppercase}}</span>
        </div>
        <div class="desc">
          <span class="name">{{item.investor.name}}</span>
          made a
          <span>{{item.payment_type | lowercase}}</span>
          of
          <span>{{item.amount | currency: "USD":'symbol':'1.0-2'}}</span>
        </div>
      </div>
      <div class="notification" *ngIf="item.hasOwnProperty('validate')">
        <div class="avatar user">
          <span>{{item.name[0] | uppercase}}</span>
        </div>
        <div class="desc">
          Check <span class="name">{{item.name}}</span>’s identify.
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!data || data.length === 0 || data === []" class="not-found">
    <span>Data not found</span>
  </div>
</section>
