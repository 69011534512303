export class Transaction {
  private static baseUrl = '/api/payments';
  private static ledgerUrl = '/api/ledger-transaction';
  private static adminUrl = '/api/admin';
  private static confirmDeposit = 'confirm-deposit';
  private static receivedDepositUrl = 'receive-deposit';
  private static confirmAllReceivedDepositsUrl = 'confirm-all-received-deposits';
  private static cancelDeposit = 'cancel-deposit';
  private static confirmWithdrawal = 'confirm-withdrawal';
  private static cancelWithdrawal = 'cancel-withdrawal';
  private static insights = 'insights';
  private static pending = 'pending';
  private static transfer = 'transfer';

  static get BaseUrl() {
    return this.baseUrl;
  }

  static get ConfirmDepositUrl() {
    return this.ledgerUrl + '/' + this.confirmDeposit;
  }

  static get ReceivedDepositUrl() {
    return this.ledgerUrl + '/' + this.receivedDepositUrl;
  }

  static get ConfirmAllReceivedDepositsUrl() {
    return this.ledgerUrl + '/' + this.confirmAllReceivedDepositsUrl;
  }

  static get CancelDepositUrl() {
    return this.ledgerUrl + '/' + this.cancelDeposit;
  }

  static get ConfirmWithdrawalUrl() {
    return this.ledgerUrl + '/' + this.confirmWithdrawal;
  }

  static get CancelWithdrawalUrl() {
    return this.ledgerUrl + '/' + this.cancelWithdrawal;
  }

  static get insightsUrl() {
    return this.ledgerUrl + '/' + this.insights;
  }

  static get Pending() {
    return this.adminUrl + '/' + this.pending;
  }

  static get Transfer() {
    return this.ledgerUrl + '/' + this.transfer;
  }

}
