<div class="table-filter" *ngIf="viewFilter">
  <ng-content select="[table-filter]"></ng-content>
</div>

<div [nbSpinner]="loading" class="table" nbSpinnerStatus="primary" [ngClass]="loading? 'main-table' : ''">
  <table *ngIf="dataSource?.length">
    <thead>
      <tr>
        <th *ngFor="let column of displayedColumns" class="text-uppercase">{{column.name}}</th>
        <th
          *ngIf="((edit || planSetting || categorySetting) && currentAdmin.role === 'SUPER') || ((deleteBtn || rollBackHistoryTable) && currentAdmin.role === 'SUPER') || paymentAction || editUser || editProPlan || editUserTeam"
          class="editColumn"></th>
        <th *ngIf="editProPlan" class="editColumn"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let row of dataSource | paginate: {itemsPerPage: limit, currentPage: page, totalItems: count, id: pageId}; let i = index"
        [ngClass]="row.suspend? 'suspend' : ''" (click)="this.rowData = row; this.ix = i">

        <td *ngFor="let column of displayedColumns" [ngClass]="{'cursor-pointer': info, 'description-col': (categorySetting && column.value === 'description') || (historyTable && column.value === 'description')}" (click)="showInfo(row, i)">
          <ng-container *ngIf="column.value === 'investor'">
            <a class="link" (click)="navigateToUserDetails(row[column.value]['id'])">{{row[column.value]?.name}}</a>
          </ng-container>
          <ng-container *ngIf="column.value === 'wallet'">
            <div style="display: flex; align-items: center; gap: 5px;" *ngIf="row[column.value]">
              <img width="30px" height="30px" [src]="row[column.value]['image']" alt="image" />
              <span>{{row[column.value]['name']}}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="column.value === 'action'">
            <div class="admin-action">{{row[column.value]}}</div>
          </ng-container>
          <ng-container *ngIf="column.value === 'image'">
            <img class="table-image" [src]="row[column.value]" alt="image" />
          </ng-container>
          <ng-container *ngIf="categorySetting && column.value !== 'activation'">
            <div (click)="navigateToCategoryDetails(row)" [ngClass]="{'category-description': categorySetting && column.value === 'description'}" style="cursor: pointer;">{{row[column.value]}}</div>
          </ng-container>
          <ng-container *ngIf="planSetting && column.value !== 'activation' && column.value !== 'is_affiliate_enabled'">
            <div class="plan-cell">{{row[column.value]}}</div>
          </ng-container>
          <ng-container *ngIf="categorySetting && column.value === 'activation'">
            <div class="plan-cell">
              <nb-toggle status="success" nbTooltip="enable / disable category" [checked]="row[column.value]" (checkedChange)="activateCategoryFun($event, row)"></nb-toggle>
            </div>
          </ng-container>
          <ng-container *ngIf="planSetting && column.value === 'activation'">
            <div class="plan-cell">
              <nb-toggle status="success" nbTooltip="enable / disable plan" [checked]="row[column.value]" (checkedChange)="activatePlanFun($event, row)"></nb-toggle>
            </div>
          </ng-container>
          <ng-container *ngIf="planSetting && column.value === 'is_affiliate_enabled'">
            <div class="plan-cell">
              <nb-toggle status="success" nbTooltip="enable / disable Affiliate" [checked]="row[column.value]" (checkedChange)="isAffiliateEnabledFun($event, row)"></nb-toggle>
            </div>
          </ng-container>
          <ng-container
            *ngIf="column.value !== 'image' && column.value !== 'investor' && column.value !== 'wallet' && column.value !== 'action' && !categorySetting && !planSetting">
            <span *ngIf="column.value === 'payment_status' || column.value === 'status'"
              [ngStyle]="{'color': row?.color}">{{row[column.value]}}</span>
            <span *ngIf="column.value !== 'payment_status' && column.value !== 'status'" [ngClass]="
            (column.value === 'type' || column.value === 'transactions_entry_type') ? 'success' : ''
            ">{{row[column.value]}}</span>
            <span *ngIf="column.value === 'sender_name'"> <span class="square sender">sender</span></span>
            <span *ngIf="column.value === 'received_name'"> <span class="square received">received</span></span>
          </ng-container>
        </td>

        <td *ngIf="deleteBtn && currentAdmin.role === 'SUPER'" class="btn-menu">
          <button [hidden]="currentAdmin.email === row.email" nbButton (click)="deleteItem(row, i)" nbTooltip="Delete">
            <nb-icon icon="trash-outline"></nb-icon>
          </button>
        </td>

        <td *ngIf="rollBackHistoryTable && currentAdmin.role === 'SUPER'" class="btn-menu">
          <button *ngIf="row.enableToDelete" [hidden]="currentAdmin.email === row.email" nbButton (click)="rollBackProfit(row, i)" nbTooltip="Delete">
            <nb-icon icon="trash-outline"></nb-icon>
          </button>
        </td>

        <td *ngIf="editProPlan" class="profitBtn">
          <button nbButton status="success" (click)="itemObj.emit(row)">
            Add profit
          </button>
        </td>

        <td *ngIf="editProPlan" class="redeemBtn">
          <button nbButton status="success" (click)="openWindow.emit(row)">
            Redeem
          </button>
        </td>

        <td *ngIf="edit && currentAdmin.role === 'SUPER'">
          <button [nbContextMenu]="walletMenu" class="context-menu" (click)="toggleViewActionsMenu(i)" nbButton>
            <nb-icon icon="settings-2-outline"></nb-icon>
          </button>
        </td>

        <td *ngIf="categorySetting && currentAdmin.role === 'SUPER'">
          <button [nbContextMenu]="categoryAndPlanMenu" class="context-menu" (click)="toggleViewActionsMenu(i)" nbButton>
            <nb-icon icon="settings-2-outline"></nb-icon>
          </button>
        </td>

        <td *ngIf="planSetting && currentAdmin.role === 'SUPER'">
          <button [nbContextMenu]="planMenu" class="context-menu" (click)="toggleViewActionsMenu(i)" nbButton>
            <nb-icon icon="settings-2-outline"></nb-icon>
          </button>
        </td>

        <td *ngIf="paymentAction">
          <button class="show-request" nbButton (click)="showRequest(i, row)">
            Show request
          </button>
        </td>

        <td *ngIf="editUser" class="btn-menu">
          <button [nbContextMenu]="userMenu" class="context-menu" nbButton (click)="getSuatusSuspend(row)">
            <nb-icon icon="settings-2-outline"></nb-icon>
          </button>

        </td>

        <td *ngIf="editUserTeam" class="btn-menu">
          <button [nbContextMenu]="userTeamMenu" class="context-menu" nbButton>
            <nb-icon icon="settings-2-outline"></nb-icon>
          </button>

        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="showPagination && dataSource?.length" class="pagination">
  <pagination-controls [id]="pageId" previousLabel="" nextLabel="" (pageChange)="pageChange.emit($event)"
    responsive="true"></pagination-controls>
</div>

<div *ngIf="!dataSource?.length && !loading" class="empty">
  <img src="/assets/images/empte-icon.svg" alt="" />
</div>
