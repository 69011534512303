import { Injectable } from '@angular/core';
import { StorageService } from '../../storage/storage.service';
import { CoreRequestService } from '../../api-services/core-request/core-request.service';
import { Auth } from '../../../models/auth/auth.class';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  constructor(
    private _coreRequest: CoreRequestService,
    private _storage: StorageService
  ) {
  }

  signIn(formData: any) {
    return this._coreRequest.post(formData, Auth.SignIn);
  }

  changePassword(formData: any) {
    return this._coreRequest.post(formData, Auth.ChangePassword);
  }

  refreshToken() {
    const model = {
      'refreshToken': this._storage.getRefreshToken()
    }
    return this._coreRequest.post(model, Auth.RefreshToken);
  }

  getAdmins() {
    return this._coreRequest.get(Auth.BaseUrl);
  }

  getCurrentAdmin() {
    return this._coreRequest.get(Auth.CurrentAdminUrl);
  }

  createAdmin(formData: any) {
    return this._coreRequest.post(formData, Auth.BaseUrl);
  }

  deleteAdmin(id: number) {
    return this._coreRequest.delete(Auth.BaseUrl + '/' + id)
  }
}
