import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { StorageService } from '../../../@core/services/storage/storage.service';
import { NbMenuService } from "@nebular/theme";

@Component({
  selector: 'ngx-ic-table',
  templateUrl: './ic-table.component.html',
  styleUrls: ['./ic-table.component.scss']
})
export class IcTableComponent {

  // moment(item.updated_at).format("YYYY-MM-DD")

  @Input() displayedColumns: any[] = [];
  @Input() dataSource: any[] = [];
  @Input() viewFilter: boolean = false;
  @Input() edit: boolean = false;
  @Input() editProPlan: boolean = false;
  @Input() info: boolean = false;
  @Input() deleteBtn: boolean = false;
  @Input() toggleBtn: boolean = false;
  @Input() paymentAction: boolean = false;
  @Output() itemObj: EventEmitter<any> = new EventEmitter<any>();
  @Output() suspended: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteUser: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteProfit: EventEmitter<any> = new EventEmitter<any>();
  @Input() showPagination = false;
  @Input() count: number;
  @Input() page: number = 1;
  @Input() limit: number = 10;
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() loading: boolean;
  @Input() pageId: string;
  @Input() editUser: boolean = false;
  @Input() editUserTeam: boolean = false;
  @Input() categorySetting: boolean = false;
  @Input() historyTable: boolean = false;
  @Input() rollBackHistoryTable: boolean = false;
  @Input() planSetting: boolean = false;
  @Output() openWindow: EventEmitter<any> = new EventEmitter<any>();
  @Output() reload: EventEmitter<any> = new EventEmitter<any>();
  @Output() openUpLiner: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPercentage: EventEmitter<any> = new EventEmitter<any>();
  @Output() categoryEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() planEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() planEditValues: EventEmitter<any> = new EventEmitter<any>();
  @Output() planActivation: EventEmitter<any> = new EventEmitter<any>();
  @Output() planAffiliateEnabled: EventEmitter<any> = new EventEmitter<any>();
  @Output() categoryActivation: EventEmitter<any> = new EventEmitter<any>();

  showEditMenu: boolean[] = [];
  routerPath: string;
  currentAdmin: any;
  rowData:any;
  i:any;
  suspend: string = 'suspend';
  userMenu: any = [
    {
      value: 'suspend',
      title: 'suspend',
      icon: 'power',
    },
    {
      value: 'Delete',
      title: 'Delete',
      icon: 'trash-outline',
    },
    {
      value: 'Show Verification',
      title: 'Show Verification',
      icon: 'checkmark-circle'
    }
  ];
  userTeamMenu = [
    {
      value: 'Change up linear',
      title: 'Change up linear',
      icon: 'edit-outline'
    },
    {
      value: 'Change affiliate profit',
      title: 'Change affiliate profit',
      icon: 'edit-outline',
    }
  ];
  walletMenu = [
    {
      value: 'Edit',
      title: 'Edit',
      icon: 'edit-outline'
    },
    {
      value: 'Delete',
      title: 'Delete',
      icon: 'trash-outline',
    }
  ];

  categoryAndPlanMenu = [
    {
      value: 'categoryEdit',
      title: 'Edit',
      icon: 'edit-outline'
    }
  ];

  planMenu = [
    {
      value: 'planEdit',
      title: 'Edit',
      icon: 'edit-outline'
    }
  ];

  menuDestroy:any;


  constructor(
    private storage: StorageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private nbMenuService: NbMenuService
    ) {
    this.subscribeToRouteChanges();
    this.currentAdmin = JSON.parse(this.storage.getClient());
    this.menuService();
    }

    getSuatusSuspend(data:any) {
      if(data.hasOwnProperty('suspend')) {
        this.suspend = 'suspend'
      } else {
        this.suspend = 'Suspend'
      }
      this.userMenu[0] =
        {
          value: 'suspend',
          title: this.suspend,
          icon: 'power',
        }
    }
    menuService() {
        this.nbMenuService.onItemClick().subscribe((event: any) => {
        switch (event.item.value) {
          case 'Suspend':
          case 'suspend':
            this.suspended.emit(this.rowData);
            break;
            case 'Delete':
            this.deleteItem(this.rowData, this.i);
            break;
            case 'Show Verification':
              this.openWindow.emit(this.rowData);
            break;
            case 'Edit':
              this.editAction(this.rowData, this.i);
            break;
            case 'categoryEdit':
              this.categoryEdit.emit(this.rowData);
            break;
            case 'planEdit':
              this.planEdit.emit(this.rowData);
            break;
            case 'Change up linear':
              this.openUpLiner.emit(this.rowData);
            break;
            case 'Change affiliate profit':
              this.openPercentage.emit(this.rowData);
            break;
        }
      })
    }
    subscribeToRouteChanges(): void {
      this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        let currentRoute = this.activatedRoute.root;
        while (currentRoute.children.length) {
          currentRoute = currentRoute.firstChild;
        }
          this.routerPath = currentRoute.snapshot.data['name'];
      });
    }

  toggleViewActionsMenu(index: number) {
    if(this.showEditMenu[index] === true) {
      this.showEditMenu[index] = false;
    } else {
      setTimeout(() => {
        this.showEditMenu[index] = !this.showEditMenu[index];
      }, 100);
    }

    this.showEditMenu.forEach((_, i) => {
      if (i !== index) {
        this.showEditMenu[i] = false;
      }
    })
  }

  editAction(data: any, index: number) {
    this.router.navigate([`/pages/${this.routerPath}/edit`, data.id]);
  }

  showInfo(data: any, index: number) {
    if(this.info) {
      this.reload.emit(data)
      this.router.navigate(['/pages/users/user', data.id])
    }
    if(this.editProPlan) {
      this.reload.emit(data)
      this.router.navigate(['/pages/users/user', data.investorId])
    }
  }

  deleteAction(index: number, item: any) {
    this.dataSource.splice(index, 1);
    this.itemObj.emit(item);
    this.toggleViewActionsMenu(-1);
  }

  deleteItem(row, i){
    this.deleteUser.emit(row)
  }

  rollBackProfit(row, i){
    this.deleteProfit.emit(row)
  }

  showRequest(index: number, item: any) {
    this.itemObj.emit(item);
  }

  activatePlanFun(event, row) {
    const model = {
      id: row.id,
      activation: event
    }
    this.planActivation.emit(model);
  }

  isAffiliateEnabledFun(event, row) {
    const model = {
      id: row.id,
      isAffiliateEnabled: event
    }
    this.planAffiliateEnabled.emit(model);
  }

  activateCategoryFun(event, row) {
    const model = {
      id: row.id,
      activation: (event) ? 'enable' : 'disable'
    }
    this.categoryActivation.emit(model);
  }

  navigateToUserDetails(id: number) {
    this.router.navigate(['/pages/users/user', id])
  }

  navigateToCategoryDetails(row: any) {
    if(row && row.id) {
      this.router.navigate(['/pages/plans/category-plans-list', row.id])
    }
  }
}
