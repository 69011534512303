import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private static tokenKey = '__at';
  private static refreshTokenKey = '__rt';
  private static clientKey = '__ck';
  private static timeKey = '__tk';
  private static activePlanInfo = '__activePlanInfo';

  //#region @translation
  setLogInTime(time: any) {
    localStorage.setItem(StorageService.timeKey, time);
  }

  getLogInTime() {
    const storage = localStorage.getItem(StorageService.timeKey);
    return storage;
  }

  //#region @token methods
  setToken(token: any): void {
    localStorage.setItem(StorageService.tokenKey, token);
  }

  getToken() {
    const storage = localStorage[StorageService.tokenKey] ? localStorage[StorageService.tokenKey] : undefined;
    return storage;
  }

  setRefreshToken(token: any): void {
    localStorage.setItem(StorageService.refreshTokenKey, token);
  }

  getRefreshToken() {
    const storage = localStorage[StorageService.refreshTokenKey] ? localStorage[StorageService.refreshTokenKey] : undefined;
    return storage;
  }

  deleteTokens() {
    localStorage.removeItem(StorageService.tokenKey);
    localStorage.removeItem(StorageService.refreshTokenKey);
  }

  isAuthorized() {
    return this.getToken() ? true : false;
  }
  //#endregion

  //#region @client Keys
  setClient(client: any) {
    localStorage.setItem(StorageService.clientKey, JSON.stringify(client));
  }

  getClient(): string | any {
    return localStorage.getItem(StorageService.clientKey);
  }

  deleteClient() {
    localStorage.removeItem(StorageService.clientKey);
    sessionStorage.clear();
  }
  //#endregion

  //#region @active plan info Keys
  setPlanInfo(planInfo: any) {
    localStorage.setItem(StorageService.activePlanInfo, JSON.stringify(planInfo));
  }

  getPlanInfo(): string | any {
    return localStorage.getItem(StorageService.activePlanInfo);
  }

  deletePlanInfo() {
    localStorage.removeItem(StorageService.activePlanInfo);
    sessionStorage.clear();
  }
  //#endregion

  logout(): void {
    this.deleteTokens();
    this.deleteClient();
    this.deletePlanInfo();
  }
}
